exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-izdelki-tsx": () => import("./../../../src/pages/izdelki.tsx" /* webpackChunkName: "component---src-pages-izdelki-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-service-page-tsx": () => import("./../../../src/pages/ServicePage.tsx" /* webpackChunkName: "component---src-pages-service-page-tsx" */),
  "component---src-pages-storitve-tsx": () => import("./../../../src/pages/storitve.tsx" /* webpackChunkName: "component---src-pages-storitve-tsx" */),
  "component---src-pages-sub-service-page-tsx": () => import("./../../../src/pages/SubServicePage.tsx" /* webpackChunkName: "component---src-pages-sub-service-page-tsx" */)
}

