import * as React from 'react';
import {useState} from 'react';
import {GatsbyBrowser} from 'gatsby';
import {MainMenuContext} from './src/lib/MainMenuContext';


export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({element}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  return <MainMenuContext.Provider value={{isMenuOpened, setIsMenuOpened}}>
    {element}
  </MainMenuContext.Provider>;
};
